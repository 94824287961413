p{
    font-family: poppins;
    font-size: 17px;
    color: #121212bf;

}

@media screen and (min-width:769px){
.rowreverse{
    flex-direction: row-reverse;
}

}
.about-container{
    margin-bottom: 60px;
}

@media screen and (max-width:769px){
    .rowreversemob{
        flex-direction: column-reverse;
    }
    }