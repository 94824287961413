.service-subsection img{
    border: 3px solid #FFA500;
    border-radius: 20px;
    padding: 20px;
    height: 120px ;
    width: 120px;
}
.service-subsection{
    padding: 100px 0 0;
}

.service-subsection h3{
    font-size: 24px;
    line-height: 30px;
    color: #121212;}

.service-subsection p{
    font-size: 16px;
    line-height: 24px;
    color: #323131bf;
}

.ServiceSectionContainer{
    margin-bottom: 60px;
}