@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
@font-face {
  font-family: Mariposa-sans;
  src: url(../src/assets/fonts/MariposaSansStd-Book.otf);
  font-display: swap;
}
* {
  box-sizing: border-box;
}

body {
  font-family: poppins !important;
  background-color: transparent;
  overflow-x: hidden;
}
.container {
  max-width: 90% !important;
  margin: 0 auto;
}
.page-container {
  max-width: 65% !important;
  margin: 0 auto;
  padding: 40px 0;
}

.page-container p {
  font-size: 17px;
  font-family: poppins;
  color: #121212bf;
  line-height: 34px;
  font-weight: 400;
}
.page-container .title--mod {
  font-size: 48px;
}
.page-container h5 {
  font-size: 17px;
  color: #121212bf;
}
.page-container a {
  color: #121212bf !important;
  text-decoration: underline !important;
}

.css-13cymwt-control {
  height: 60px !important;
  border-radius: 4px !important;
  border: 1px solid #808080 !important;
}
.collection-container {
  padding: 30px 0;
}
.linkBtn {
  border: 1px solid orange;
  background: orange;
  color: white !important;
  text-decoration: none;
  padding: 12px 30px;
  font-size: 15px;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}
.linkBtn:hover {
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}
.anchorLink {
  text-decoration: underline !important;
  color: #121212 !important;
  font-size: 16px;
}

.anchorLink:hover {
  font-weight: 400;
}
.contact-details-title {
  font-size: 17px;
  font-weight: 400;
  color: #121212;
}
.details-info{
  font-size: 14px;
 color: #808080;
}
.backbtn{
  color:#a04801 !important;
  font-size: 16px;

}
.backbtn img{
  height: 11px;
  position: relative;
  top: -1px;
}

.modal-open{
  padding-right: 0 !important;

}
.search-modal-container .modal-dialog{
  max-width: 100%;
  margin: 0;
}
.modal {
  padding-right: 0 !important;
}
/* .css-t3ipsp-control{
 border-color: transparent !important;
 box-shadow: 0 0 0 1px #1a1a1a !important;}

.css-13cymwt-control {
  box-shadow: none;
} */
.reset-password-modal .modal-dialog{
  transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0 auto !important;
}

.checkout-modal .modal-dialog{
  max-width: 70%;
}
.search-modal-container .modal-content{
  border-radius: 0;
}
.test{
  background-color: pink;
  padding: 90px;
}
@media only screen and (max-width: 769px) {
  .page-container {
    max-width: 95% !important;
  }
  .page-container p {
    font-size: 16px;

    line-height: 32px;
  }
  .page-container .title--mod {
    font-size: 40px;
  }
}
