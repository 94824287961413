
.card-image-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.card-image-container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: 0.5s ease all;
}
.card-image-container img {
  transition: 0.5s ease all;
  width: 100%;
  object-fit: cover;
}
.card-image-container:hover img {
  transform: scale(1.1);
}

.card-image-.card-image-container::after {
  opacity: 0.2;
}

.card-container h5{
  font-family: Mariposa-sans !important;
  font-size: 22px;
  color: #121212bf;
}

 a{
  text-decoration: none !important;

}

@media screen and (max-width:769px) {
  .card-container h5{
    margin-bottom: 30px;
  }

}

/* @media screen and (min-width:767px){ */
  .card-image-container img {
    height: 280px;
  }
/* } */