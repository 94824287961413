.section-container {
  padding: 36px 0px;
}

.benefit-container img {
  width: 100vw;
}
.section-sub-container {
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  font-family: poppins;
  font-size: 17px;
  color: #121212bf;
  line-height: 34x;
}
.video-container {
  height: 500px;
  width: 100%;
}
.gbr-partner{
  background-color: #3ECF8E;
  padding: 30px 20px;
  width: 60%;
}
@media screen and (max-width: 769px) {
  .section-container {
    padding: 36px 0px 0px 0px;
  }
  .video-container{
   height: auto;
  }
  .gbr-partner{
    background-color: #3ECF8E;
    padding: 20px 10px;
    width: 80%;
    text-align: center;
    display:block;
    margin-left: auto;
    margin-right: auto;
  }
}

.slick-prev:before, .slick-next:before{
  color: orange !important;
  font-size: 32px !important;
}