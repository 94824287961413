.CommonBannerContainer{
    padding: 20px 0;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/marble-compostion-with-colorful-style.jpg");
}
.CommonBannerContainer h1{
    font-size: 40px;
    line-height: 55px;
    color: #121212;
    font-weight: 500;
    font-family: Mariposa-sans;
}

.honeycomb {
  
    height: 82px;
    width: 100%;
    background-image: url("../../assets/images/41 (2).jpg");
    margin-bottom: 50px;
    background-size: contain;
    background-position: bottom;
    background-repeat: repeat;
}