.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 20;
  border-bottom: 0.8px solid rgb(231 229 229);
  height: 100px;
  background: white;
  z-index: 9;
}

.logo-container img{
  width: 90px;
}
.nav-container {
  height: 100px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-menu {
  width: 80%;
}

.nav-links {
  color: #121212bf;
  text-decoration: none;
  height: 100%;
  margin: 20px;
  border-bottom: 1px solid transparent;
  font-size: 16px;
}
.fa-code {
  margin-left: 1rem;
}

.nav-icon {
  display: none;
}

.icon-container svg,
.mobile-icons svg {
  width: 23px;
  height: 23px;
  margin: 0 8px;
  color: #3c3c3cbf;
  font-weight: 100;
}
.icon-container--modt{
  width: 28px !important;
  height: 30px !important;
}
.icon-container--mody{
  width: 31px !important;
  height: 30px !important;
}
.mobile-icons {
  display: none;
}
.nav-item-container {
  width: 100%;
}
.navbar .mobile-hidden {
  display: initial !important;
}
.navbar .mobile-visible {
  display: none !important;
}
.nav-item-container {
  justify-content: space-between;
}
.nav-item a:hover,
a.active {
  border-bottom: 1px solid #121212;
  color: #121212;
}
.cart-count {
  position: absolute;
  top: -5px;
  right: 3px;
  border-radius: 50%;
  background-color: orange;
  color: white;
  padding: 2px;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1150px) {
  .nav-item a:hover,
  a.active {
    border-bottom: none;
    color: #121212;
  }
  .logo-container img{
  width: 70px;
  }
  .mobile-icons {
    display: block;
  }
  .mobile-icons svg {
    width: 24px;
    height: 24px;
    margin: 0 8px;
    color: #323131bf;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 70%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: white;
  }

  .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 30px;
  }
  .nav-item .active {
    border: none;
  }
  .nav-links {
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    /* transform: translate(40%, 60%); */
    cursor: pointer;
    color: #323131bf;
  }
  .nav-icon svg {
    width: 28px;
    height: 28px;
  }
  
  .navbar,
  .nav-container {
    height: 80px;
  }
  a.active {
    border-bottom: none !important;
  }

  .nav-rt {
    margin-top: 30px;
    margin-bottom: 40px !important;
    flex-direction: column;
  }
  .menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    cursor: pointer;
  }
  .nav-item-container {
    flex-direction: column;
  }

  .navbar .mobile-hidden {
    display: none !important;
  }
  .navbar .mobile-visible {
    display: initial !important;
  }
  .nav-lf {
    flex-direction: column;
    background-color: #dbdadabf;
    padding: 20px 0 20px 4%;
  }

  .nav-lf li a {
    color: #323131bf;
    text-decoration: none;
  }
  .nav-item-container {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .nav-links {
    margin: 10px 0 10px 6%;
  }
}


.dropdown .btn-primary {
  color: #121212bf;
  text-decoration: none;
  height: 100%;
  margin: 0 20px;
  font-size: 16px;
  background-color: white;
  padding: 0;
  border: none;
}
.dropdown button:hover {
  transform: none;
}
.dropdown .btn:first-child:active {
  background: none;
  color: black;
}
.dropdown .nav-item {
  color: #121212bf;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: white !important;
}
.dropdown-toggle::after{
  border: none !important;
}
@media screen and (max-width: 769px) {
  .logo-container img {
    height: 100%;
    width: 70px;
  }
  .nav-links {
    margin: 10px 0 10px 8%;
  }
  .mobile-icons svg {
    width: 20px;
    height: 20px;
    margin: 0 6px;
  }
  .nav-icon svg {
    width: 24px;
    height: 24px;
  }
  .cart-count {
    top: -3px;
    right: 0px;
    font-size: 8px;
    height: 16px;
    width: 16px;
  }
  .dropdown .btn-primary {
    margin: 10px 0 10px 8%;
  }
}
