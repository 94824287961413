@font-face {
    font-family: Mariposa-sans;
    src: url(../../assets/fonts/MariposaSansStd-Book.otf);
    font-display: swap;
  }
.title{
    font-family: Mariposa-sans;
    font-size: 40px;
    color: #121212;
}

@media screen and (max-width:769px) {
 .title{ font-size: 30px;
 }
}