.USPcards{
    padding: 0 20px;
}

.USPcards img{
    height: auto;
    width: 45px;
}
.USPcards h2{
    font-size: 16px;
    line-height: 26px;
    color: #121212;
}

.USPcards p{
    font-size: 16px;
    line-height: 24px;
    color: #323131bf;
}