input[type=checkbox] {
    position: relative;
      border: 1px solid orange;
      border-radius: 2px;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 0 !important;
      vertical-align: text-top;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
    opacity: .5;
  }
  
  
  
  input[type=checkbox]:checked {
    background-color:orange;
    opacity: 1;
  }
  
  input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

 .mobile-hidden {
    display: initial !important;
  }
 .mobile-visible {
    display: none !important;
  }

  .filter-sort-container h6, label{
    font-size: 17px;
    color: #323131bf;
    font-weight: 400;
  }
  .filter-sort-container select{
    border:0.8px solid #323131bf;
    color: #323131bf;
    font-size: 14px;
    padding: 4px;
    outline: none !important;
  }

  .filter-ofcanvas-container img {
    width: 20px;
    height: 20px;
  }
  
  .offcanvas-body h3 {
    font-size: 18px;
    font-weight: 500;
    color: #121212bf;
  }
  .offcanvas-header {
    display: block;
    border-bottom: 0.8px solid rgb(231 229 229);
  }
  .offcanvas-header h5 {
    font-size: 16px;
    color: #121212;
  }
  
  .offcanvas-header p {
    font-size: 14.5px;
    color: #121212b3;
    font-weight: 400;
  }
  
  .offcanvas-header .btn-close {
    position: absolute;
    right: 20px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
  
  .offcanvas-footer {
    border-top: 0.8px solid rgb(231 229 229);
    padding: 20px;
  }
  
  .offcanvas-footer button {
    width: 150px;
  }
  
  .offcanvas-footer .btn-primary {
    background-color: #fff;
    color: orange;
  }


/* .checkInput{
  height: 50px !important;
} */

.pricerange-container{
  border: 0.8px solid rgb(201 200 200);
  border-radius: 50px;
  padding: 6px 10px;
  color: #121212bf;
  font-size: 14px;
  font-weight:400;
}
.pricerange-container span{
  cursor: pointer;
}
.remove-all-btn{
  color: #121212bf;
  font-size: 14px;
  cursor: pointer;
  font-weight:400;
  text-decoration: underline;

}
.highestPrice{
  font-size: 17px;
  color: #323131bf;
  font-weight: 400;
}
.check {
  height: 50px !important;
}
/* .pricerange-container span{
  font-size: 5px;
} */

.price-filter-container input{
  width: 80%;
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 8px;
  font-size: 16px;}

  .price-filter-container input::placeholder {
    font-size: 12px;
}
 

@media screen and (max-width:769px){
  .mobile-hidden {
      display: none !important;
    }
   .mobile-visible {
      display: initial !important;
    }
    .offcanvas-footer button{
      width:140px;
    }
    .filter-sort-container h6, label {
      font-size: 15px;
    }    

    .pricerange-container,.remove-all-btn {
      padding: 2px 10px;
      color: #121212bf;
      font-size: 13px;
    }
}
